<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="candidate"
    name="propertyVisitEditBooking"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="submit"
  >
    <FormulateInput
      v-model="candidate.email"
      type="email"
      name="email"
      label="Emailadres"
      help="U kunt het e-mailadres niet wijzigen"
      :show-required-label="true"
      disabled
    />
    <div class="tw-w-full tw-grid md:tw-grid-cols-2 tw-gap-4">
      <FormulateInput
        type="text"
        name="first_name"
        label="Voornaam"
        validation="required:trim"
        :show-required-label="true"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="text"
        name="last_name"
        label="Naam"
        validation="required:trim"
        :show-required-label="true"
        outer-class="tw-m-0"
      />
    </div>
    <FormulateInput
      type="select"
      name="language"
      label="Voorkeurstaal"
      placeholder="Selecteer taal"
      value="nl"
      :options="USER_LANG_OPTIONS"
      :show-required-label="true"
      validation="required"
    />
    <FormulateInput
      type="dial_code_tel"
      name="phone_number_mobile"
      label="GSM nummer"
      :show-required-label="true"
      validation="bail|required|phone"
    />
    <FormulateErrors />
    <div class="tw-flex tw-space-x-4 tw-items-end">
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        :input-class="['tw-h-8 tw-px-4 md:tw-text-sm tw-w-full']"
      >
        <i
          :class="[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-floppy'
          ]"
        />
        Opslaan
      </FormulateInput>
      <FormulateInput
        type="button"
        :input-class="['tw-h-8 tw-px-4 md:tw-text-sm tw-w-full tw-bg-error']"
        @click="$emit('cancel')"
      >
        <i class="fa fa-times tw-mr-2" /> Annuleren
      </FormulateInput>
    </div>
  </FormulateForm>
</template>

<script>
import { updatePropertVisitBooking } from '@/services/calendarService'
import { successModal } from '@/modalMessages'
import { USER_LANG_OPTIONS } from '@/utils/helpers'

export default {
  name: 'PropertyVisitEditBooking',
  props: {
    slotId: {
      type: Number,
      required: true
    },
    booking: {
      type: Object,
      required: true
    }
  },
  constants: { USER_LANG_OPTIONS },
  data () {
    return {
      propertyId: this.$route.params.id,
      candidate: {}
    }
  },
  created () {
    this.candidate = { ...this.booking.candidate }
  },
  methods: {
    submit (data) {
      return updatePropertVisitBooking(this.propertyId, this.slotId, this.booking.id, { candidate: data })
        .then(response => {
          successModal('Details bijgewerkt')
          this.$emit('update', response.data)
        })
        .catch(error => {
          this.$formulate.handle(error, 'propertyVisitEditBooking')
        })
    }
  }
}
</script>
